import { useRepo } from "pinia-orm";
import { validate as uuidValidate } from "uuid";
import { api } from "src/boot/axios.js";

import MD5 from "src/utils/md5.js";

import { Contact, ContactEmail, ContactPhone } from "src/models/Contact.js";
import { File } from "src/models/File.js";
import { JournaObject } from "src/models/Jobject.js";
import { Location } from "src/models/Location.js";
import { Conversation, Message } from "src/models/Message.js";
import { Node } from "src/models/Node.js";
import { Customer } from "src/models/Payment.js";
import { Routable } from "src/models/Routable.js";
import { Tag } from "src/models/Tag.js";
import { Transaction, PaymentSeries, Invoice } from "src/models/Transaction.js";
import { User, Group } from "src/models/User.js";

function DBFactory(repo) {
  function isPaginated(data) {
    return data.hasOwnProperty("count") && data.hasOwnProperty("results");
  }

  return {
    orm: () => useRepo(repo),
    create: async (path, data, key, hash) => {
      const response = await api.post(path, data);
      if (key && hash) {
        useRepo(repo).save({
          $hash: {
            [key]: hash,
          },
          ...response.data,
        });
      } else {
        useRepo(repo).save(response.data);
      }

      return response.data;
    },
    readIds: async (path, ids) => {
      if (!ids.length) {
        console.error(
          "db.js: read_ids(path, ids) `ids` must be an array of ids or uuids."
        );
        return;
      }

      let idField = "ids";
      if (typeof ids[0] == "string") {
        idField = "uuids";
      }

      const response = await api.post(`${path}/bulk_id_fetch`, {
        [idField]: ids,
      });

      useRepo(repo).save(response.data);

      return response.data;
    },
    read: async (path, key, query, refetching) => {
      const $repo = useRepo(repo);
      if (typeof query == "object") {
        let q = $repo.query().withAllRecursive();
        Object.keys(query).forEach((term) => {
          q = q.where(term, query[term]);
        });

        let results = q.get();
        if (results.length) {
          DBFactory(repo).read(path, key);
          return results;
        }
      } else if (typeof query == "function") {
        let results = $repo.query().withAllRecursive().where(query).get();
        if (results.length) {
          DBFactory(repo).read(path, key);
          return results;
        }
      }

      const response = await api.get(path);

      if (isPaginated(response.data)) {
        if (!response.data.results.length) return response.data;

        if (key) {
          const hash = MD5(path);

          if (refetching) {
            $repo
              .where(
                (row) =>
                  row["$hash"].hasOwnProperty(key) &&
                  !response.data.results.find((result) => result.id == row.id)
              )
              .get()
              .forEach((row) => {
                $repo.save({
                  ...row,
                  ["$hash"]: {
                    ...row["$hash"],
                    [key]: undefined,
                  },
                });
              });
          }

          let ormMap = new Map();
          $repo
            .where((row) =>
              response.data.results.map((result) => result.id).includes(row.id)
            )
            .get()
            .forEach((row) => {
              if (!ormMap.has(row.id)) {
                ormMap.set(row.id, row["$hash"]);
              }
            });

          let results = [];
          response.data.results.forEach((result) => {
            results.push({
              $hash: {
                ...(ormMap.has(result.id) ? ormMap.get(result.id) : {}),
                [key]: hash,
              },
              ...result,
            });
          });
          $repo.save(results);
        } else {
          $repo.save(response.data.results);
        }
      } else {
        $repo.save(response.data);
      }

      return response.data;
    },
    bulkCreate: async (path, data) => {
      const response = await api.post(path, data);
      useRepo(repo).save(response.data);
    },
    bulkUpdate: async (path, data) => {
      const response = await api.patch(path, data);
      useRepo(repo).save(response.data);
    },
    bulkDelete: async (path, ids) => {
      await api.patch(path, {
        objects: ids.map((id) => {
          return { id: id };
        }),
      });
      ids.forEach((id) => {
        useRepo(repo).destroy(id);
      });
    },
    update: async (path, data) => {
      let splitPath = path.split("/");
      let id = splitPath[splitPath.length - 1];
      if (!uuidValidate(id)) {
        id = parseInt(id);
      }

      useRepo(repo).save({ ...data, id: id });
      const response = await api.patch(path, data);

      return response.data;
    },
    delete: async (path) => {
      let splitPath = path.split("/");
      let id = splitPath[splitPath.length - 1];

      const response = await api.delete(path);

      if (!uuidValidate(id)) {
        id = parseInt(id);
      }

      useRepo(repo).destroy(id);
    },
    softDelete: async (path, data) => {
      let splitPath = path.split("/");
      let id = splitPath[splitPath.length - 1];

      const response = await api.patch(path, data);

      if (!uuidValidate(id)) {
        id = parseInt(id);
      }

      useRepo(repo).destroy(id);
    },
    archive: async (path, data) => {
      let splitPath = path.split("/");
      let id = splitPath[splitPath.length - 1];

      const response = await api.patch(path, data);

      if (!uuidValidate(id)) {
        id = parseInt(id);
      }
      //THIS LOGIC IS NOT PERFECT - YOU COULD BE DELETING SOMETHING FROM THE FRONTEND THAT SHOULD BE BEING DISPLAYED
      useRepo(repo).destroy(id);
    },
  };
}

export default {
  contacts: DBFactory(Contact),
  contact_emails: DBFactory(ContactEmail),
  contact_phones: DBFactory(ContactPhone),
  files: DBFactory(File),
  jobjects: DBFactory(JournaObject),
  locations: DBFactory(Location),
  conversations: DBFactory(Conversation),
  messages: DBFactory(Message),
  nodes: DBFactory(Node),
  customers: DBFactory(Customer),
  routables: DBFactory(Routable),
  tags: DBFactory(Tag),
  transactions: DBFactory(Transaction),
  payment_series: DBFactory(PaymentSeries),
  invoices: DBFactory(Invoice),
  users: DBFactory(User),
  groups: DBFactory(Group),
};
