const routes = {
  landing: [
    {
      name: "landing",
      path: "/",
      component: () => import("src/layouts/LandingLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          meta: {
            title: "Waste",
            description:
              "Get expert lawn care with wastecap.io: mowing, blowing, weed control, edging, and more. Call (601) 808-5296 for top services.",
          },
          component: () => import("src/pages/landing/IndexPage.vue"),
        },

        {
          name: "privacy",
          path: "privacy",
          meta: {
            title: "Privacy Policy - Waste Cap ",
            description:
              "Privacy policy for wastecap.io. This privacy policy must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "terms",
          path: "terms",
          meta: {
            title: "Terms of Use - Waste Cap",
            description:
              "Terms of use for wastecap.io. These terms of use must be consented to in order to use this website.",
          },
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "copyright",
          path: "copyright",
          meta: {
            title:
              "Lawn Care and Landscaping Marketplace - Waste Cap Copyright",
            description: "Copyright information for wastecap.io",
          },
          component: () => import("src/pages/legal/CopyrightPolicy.vue"),
        },
        {
          name: "policies",
          path: "policies",
          meta: {
            title: "Company Policies - Waste Cap",
            description:
              "List of company policies. Company policies for customers and service providers that must be consented to to use our application.",
          },
          component: () => import("src/pages/legal/CompanyPolicies.vue"),
        },
      ],
    },
    {
      name: "basic",
      path: "/",
      component: () => import("src/layouts/BasicLandingLayout.vue"),
      children: [
        {
          name: "reset-password",
          path: "/password-reset/confirm/:uid/:token",
          component: () => import("src/pages/PasswordResetPage.vue"),
        },
      ],
    },

    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],
  investor: [
    {
      name: "investor",
      path: "/",
      component: () => import("src/layouts/CustomerLayout.vue"),
      children: [
        {
          name: "index",
          path: "",
          component: () => import("src/pages/customer/CustomerIndex.vue"),
        },
        {
          name: "disclosures",
          path: "disclosures",
          component: () =>
            import("src/pages/customer/portal/DisclosuresPage.vue"),
        },
        {
          name: "change-password",
          path: "/change-password",
          component: () => import("src/pages/ChangePassword.vue"),
        },

        {
          name: "copiah-landfill-prospect",
          path: "copiah-landfill-prospect",
          component: () =>
            import("src/pages/customer/portal/CopiahLandfill.vue"),
        },
        {
          name: "offers",
          path: "offers",
          component: () => import("src/pages/customer/portal/OffersPage.vue"),
        },
        {
          name: "offer",
          path: "offers/:id",
          component: () => import("src/pages/customer/portal/OfferPage.vue"),
        },

        {
          name: "user",
          path: "user",
          component: () => import("src/components/portal/User.vue"),
        },
        {
          name: "security",
          path: "security",
          component: () => import("src/components/portal/Security.vue"),
        },
        {
          name: "terms",
          path: "terms",
          component: () => import("src/pages/legal/TermsAndConditions.vue"),
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("src/pages/legal/PrivacyPolicy.vue"),
        },
        {
          name: "profile",
          path: "/profile",
          component: () => import("src/pages/crew/CrewProfile.vue"),
        },
        {
          name: "preferences",
          path: "/preferences",
          component: () =>
            import("src/pages/customer/portal/UserPreferences.vue"),
        },
      ],
    },
    {
      name: "catchAll",
      path: "/:catchAll(.*)*",
      component: () => import("pages/ErrorNotFound.vue"),
    },
  ],
};

export default routes;
